@for $size from 0 through 10 {
  .p#{$size} {
    padding: #{$size}rem;
  }

  .p_#{$size} {
    padding: #{$size * 0.1}rem;
  }

  .pi#{$size} {
    padding-inline: #{$size}rem;
  }

  .pi_#{$size} {
    padding-inline: #{$size * 0.1}rem;
  }

  .pb#{$size} {
    padding-block: #{$size}rem;
  }

  .pb_#{$size} {
    padding-block: #{$size * 0.1}rem;
  }

  .pb_#{$size} {
    padding-block: #{$size * 0.1}rem;
  }

  .g_#{$size} {
    gap: #{$size * 0.1}rem;
  }


  @each $o, $n in (i: inline, b: block) {
    .p#{$o}s#{$size} {
      padding-#{$n}-start: #{$size}rem;
    }

    .p#{$o}e#{$size} {
      padding-#{$n}-end: #{$size}rem;
    }

    .p#{$o}s_#{$size} {
      padding-#{$n}-start: #{$size * 0.1}rem;
    }

    .p#{$o}e_#{$size} {
      padding-#{$n}-end: #{$size * 0.1}rem;
    }

    .fxgr#{$size} {
      flex-grow: $size;
    }
  }

  .t#{$size} {
    top: #{$size}rem;
  }

  .l#{$size} {
    left: #{$size}rem;
  }

  .b#{$size} {
    bottom: #{$size}rem;
  }

  .r#{$size} {
    right: #{$size}rem;
  }

  .z#{$size} {
    z-index: $size;
  }

  .fxgr#{$size} {
    flex-grow: $size;
  }


  .g#{$size} {
    gap: #{$size}rem;
  }

  .r#{$size} {
    border-radius: #{$size}px;
  }

  .r#{$size}_ {
    border-radius: #{$size}px !important;
  }
}

.fssm {
  font-size: small;
}

.fsxs {
  font-size: x-small;
}

.fsmd {
  font-size: medium;
}

.fslg {
  font-size: large;
}

.fxrw {
  flex-direction: row;
}

.fxcl {
  flex-direction: column;
}

.rel {
  position: relative;
}


@each $pos in ("start", "end", "center") {
  .a-#{$pos} {
    align-items: #{$pos};
  }

  .m-#{$pos} {
    justify-content: #{$pos};
  }

  .as-#{$pos} {
    align-self: #{$pos};
  }

  .ms-#{$pos} {
    justify-self: #{$pos};
  }
}


.ycenter {
  align-items: center;
}

.yend {
  align-items: end;
}

.a-start {
  align-items: start
}

.pabs {
  position: absolute;
}

.prel {
  position: relative;
}

.tcenter {
  text-align: center;
}


.xbetween {
  justify-content: space-between;
}

.xcenter {
  justify-content: center;
}

.xevenly {
  justify-content: space-evenly;
}

.xend {
  justify-content: end;
}

.xstart {
  justify-content: start;
}


.ybetween {
  align-items: space-between;
}

.ystretch {
  align-items: stretch;
}


.fxwrap {
  flex-wrap: wrap;
}

.hidden {
  display: none;
}

.fx {
  display: flex;
}

@for $i from 0 through 20 {
  $size: $i * 5;

  .w#{$size} {
    width: #{$size + "%"};
  }
}

@for $i from 1 through 9 {
  .rail_grid#{$i} {
    display: grid;
    grid-template-columns: #{$i}rem 1fr;
    width: 20rem;
    align-items: center;
  }
}


.gray {
  color: rgb(128, 126, 126);
}